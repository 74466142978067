import { render, staticRenderFns } from "./TakahChart.vue?vue&type=template&id=3f7ba8c2&scoped=true&"
import script from "./TakahChart.vue?vue&type=script&lang=js&"
export * from "./TakahChart.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f7ba8c2",
  null
  
)

export default component.exports